import _ from 'lodash';
import { SupportedUserRoles } from '../../components/edit-user-modal/interfaces';
import { IntegrationFilter } from '../../models/integration/integration-filter';
import { Template } from '../../models/integration/template.model';
import { UIConfig } from './config-service';
import { CCApiService } from './api-service';
import { ApiError } from '../../components/errors';

export class IntegrationApiService {
  static ApiService: CCApiService;
  static integrationApi: string;
  static apiBasePath: string;

  static init(config: UIConfig) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.integrationApi = `${config.apiBasePath}/customers`;
  }

  static async getIntegrationClientSecrets({
    customerId,
    solutionId,
    integration,
    templateCode
  }): Promise<{ authorizationServer: string; clientId: string; clientSecret: string }> {
    return this.ApiService.performFetch(
      `${this.integrationApi}/${customerId}/solutions/${solutionId}/integrations/${templateCode}/${integration.id}/credentials`
    );
  }

  static async getTemplates(): Promise<Template[]> {
    return this.ApiService.performFetch(`${this.apiBasePath}/integrations/templates`);
  }

  static async createIntegration({ customerId, solutionId, integration, templateCode }) {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ ...{ ...integration }, customerId, solutionId }),
    };
    return this.ApiService.performFetch(
      `${this.integrationApi}/${customerId}/solutions/${solutionId}/integrations/${templateCode}`,
      fetchOptions
    ).catch(this._mapApiErrorCodeToMessage);
  }

  static async updateIntegration({ customerId, solutionId, integration, templateCode }) {
    const fetchOptions = {
      method: 'PUT',
      body: JSON.stringify({ ...{ ...integration }, customerId, solutionId }),
    };
    return this.ApiService.performFetch(
      `${this.integrationApi}/${customerId}/solutions/${solutionId}/integrations/${templateCode}/${integration.id}`,
      fetchOptions
    ).catch(this._mapApiErrorCodeToMessage);
  }

  static _mapApiErrorCodeToMessage(error) {
    if (error instanceof ApiError && error.message === 'E0000038') {
      error.message = 'The integration cannot be added. Please contact customer support.';
    }
    if (error instanceof ApiError && error.message === 'E0000001') {
      error.message = 'redirect_uris must be an array of absolute URIs.';
    }
    throw error;
  }

  static async deleteIntegration({ customerId, solutionId, id, templateCode }) {
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(
      `${this.integrationApi}/${customerId}/solutions/${solutionId}/integrations/${templateCode}/${id}`,
      fetchOptions
    );
  }

  static async getIntegrations(filter: IntegrationFilter = {}) {
    const { customerId, solutionId, integratedSystemId, templateId } = filter;
    const params = this.getQueryString({integratedSystemId, templateId});
    const url = solutionId
    ? `${this.integrationApi}/${customerId}/solutions/${solutionId}/integrations`
    : `${this.integrationApi}/${customerId}/integrations`;
    return this.ApiService.performFetch(`${url}${params}`);
  }

  static getQueryString = (filter: IntegrationFilter = {}) => {
    // Remove properties with null or undefined values
    const filteredObj = _.pickBy(filter, _.identity);
    const params = new URLSearchParams(filteredObj as any);
    if (params && params.toString()) {
      return `?${params}`;
    }
    return '';
  };

  static async getIntegration(integration: {templateCode: string, id: string}) {
    const { templateCode, id } = integration;
    const url = `${this.apiBasePath}/integrations/${templateCode}/${id}`;
    return this.ApiService.performFetch(url);
  }

  static async getSupportedRoles(customerId): Promise<SupportedUserRoles> {
    return this.ApiService.performFetch(`${this.integrationApi}/${customerId}/roles`).then(result => result);
  }
}
